export enum EtkinlikRenkler {
    green = "green",
    yellow = "yellow",
    red = "red",
}
export enum EtkinlikRenklerLabel {
    green = "Normal",
    yellow = "Önemli",
    red = "Çok Önemli",

}

