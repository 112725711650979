






















import {Component, Prop, Vue} from "vue-property-decorator";
import Dates from "@/components/inputs/Dates.vue";
import NoterSecim from "@/components/inputs/NoterSecim.vue";
import ObjectInputMixin from "@/mixins/ObjectInputMixin.vue";
import OnemPicker from "@/components/pickers/OnemPicker.vue";

@Component({
    components: {
        OnemPicker,
        NoterSecim,
        Dates,
    }
})
export default class EtkinlikForm extends ObjectInputMixin {

};
