import {EtkinlikEntity} from "@/entity/EtkinlikEntity";
import {EtkinlikRenkler} from "@/enum/EtkinlikRenkler";

export interface EtkinlikResponse {
    data: Array<EtkinlikEntity>
}

export function EtkinlikResponseProvider(depth: number = 1): EtkinlikResponse {
    return {
        data: [
            {
                id: 1,
                adi: "Duruşma",
                baslangicTarihi: new Date("2020-10-03 10:00:00"),
                bitisTarihi: new Date("2020-10-03 15:00:00"),
                onem: EtkinlikRenkler.red,
                detay: "İstanbul 12.Sulh Ceza Mahkemesinde Görülecek Duruşma",
            },
            {
                id: 2,
                adi: "Randevu",
                baslangicTarihi: new Date("2020-10-05 10:00:00"),
                bitisTarihi: new Date("2020-10-05 12:00:00"),
                onem: EtkinlikRenkler.yellow,
                detay: "Avukat Engin Koçak ile randevu",
            },
            {
                id: 3,
                adi: "Doğum Günü",
                baslangicTarihi: new Date("2020-10-05 14:44:00"),
                bitisTarihi: new Date("2020-10-05 19:00:00"),
                onem: EtkinlikRenkler.green,
                detay: "Biricik kızım Kardelen'in doğum günü",
            },
            {
                id: 4,
                adi: "Toplantı",
                baslangicTarihi: new Date("2020-10-09 10:00:00"),
                bitisTarihi: new Date("2020-10-09 12:00:00"),
                onem: EtkinlikRenkler.red,
                detay: "Genç Avukatlar Derneği Olağan Yılsonu Toplantısı",
            },
            {
                id: 5,
                adi: "Toplantı",
                baslangicTarihi: new Date("2020-11-10 13:00:00"),
                bitisTarihi: new Date("2020-11-10 16:00:00"),
                onem: EtkinlikRenkler.red,
                detay: "Zirve Bilgisayar ile özel yazılım toplantısı",
            },


        ]
    }
}