












import {Component, Mixins} from "vue-property-decorator";
import EnumPicker from "@/components/common-inputs/EnumPicker.vue";
import SimpleInputMixin from "@/mixins/SimpleInputMixin.vue";
import {EtkinlikRenkler, EtkinlikRenklerLabel} from "@/enum/EtkinlikRenkler";


@Component({
    components: {EnumPicker}
})
export default class OnemPicker extends Mixins(SimpleInputMixin) {
    turler = EtkinlikRenkler;
    turlerLabel = EtkinlikRenklerLabel;
}
