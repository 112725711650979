




















































































import {Component, Vue} from 'vue-property-decorator'
import {EtkinlikResponseProvider} from "@/services/EtkinlikService";
import {EtkinlikEntity} from "@/entity/EtkinlikEntity";
import FormDialogButton from "@/components/common-inputs/FormDialogButton.vue";
import EtkinlikForm from "@/components/comps/forms/EtkinlikForm.vue";
@Component({
    components: {EtkinlikForm, FormDialogButton}
})
export default class Calendar extends Vue {
    Etkinlikler = EtkinlikResponseProvider().data;
    focus = ''
    type = 'month'
    today = ''
    typeToLabel = {
        month: 'Aylık',
        week: 'Haftalık',
        day: 'Günlük',
        '4day': '4 Günlük'
    }
    start: Start | null = null
    end: End | null = null
    selectedEvent = {}
    selectedElement = null
    selectedOpen = false
    events: Event[] = []

    get title() {
        const {start, end} = this
        if (!start || !end) {
            return ''
        }
        const startMonth = this.monthFormatter(start)
        const endMonth = this.monthFormatter(end)
        const suffixMonth = startMonth === endMonth ? '' : endMonth
        const startYear = start.year
        const endYear = end.year
        const suffixYear = startYear === endYear ? '' : endYear
        const startDay = start.day + this.nth(start.day)
        const endDay = end.day + this.nth(end.day)
        switch (this.type) {
            case 'month':
                return `${startMonth} ${startYear}`
            case 'week':
            case '4day':
                return `${startMonth} ${startDay} ${startYear} - ${suffixMonth} ${endDay} ${suffixYear}`
            case 'day':
                return `${startMonth} ${startDay} ${startYear}`
        }
        return ''
    }

    get monthFormatter() {
        return this.calendarInstance.getFormatter({
            timeZone: 'UTC', month: 'long'
        })
    }

    mounted() {
        this.calendarInstance.checkChange()
    }

    viewDay({date}: { date: string }) {
        this.focus = date
        this.type = 'day'
    }

    getEventColor(event: Event) {
        return event.color
    }

    setToday() {
        this.focus = this.today
    }

    prev() {
        this.calendarInstance.prev()
    }

    next() {
        this.calendarInstance.next()
    }

    showEvent({nativeEvent, event}: { nativeEvent: any; event: Event }) {
        const open = () => {
            this.selectedEvent = event
            this.selectedElement = nativeEvent.target
            setTimeout(() => (this.selectedOpen = true), 10)
        }
        if (this.selectedOpen) {
            this.selectedOpen = false
            setTimeout(open, 10)
        } else {
            open()
        }
        nativeEvent.stopPropagation()
    }

    updateRange({start, end}: { start: Start; end: End }) {
        const events: Event[] = []
        this.Etkinlikler.forEach((etkinlik: EtkinlikEntity) => {
            if (etkinlik.adi != null && etkinlik.baslangicTarihi != null && etkinlik.bitisTarihi != null && etkinlik.onem != null && etkinlik.detay != null) {
                events.push({
                    name: etkinlik.adi,
                    start: this.formatDate(etkinlik.baslangicTarihi, true),
                    end:this.formatDate(etkinlik.bitisTarihi, true),
                    color: etkinlik.onem,
                    details: etkinlik.detay,
                })
            }
        });

        this.start = start
        this.end = end
        this.events = events
    }

    nth(d: number) {
        return d > 3 && d < 21
            ? 'th'
            : ['th', 'st', 'nd', 'rd', 'th', 'th', 'th', 'th', 'th', 'th'][d % 10]
    }

    rnd(a: number, b: number) {
        return Math.floor((b - a + 1) * Math.random()) + a
    }

    formatDate(a: Date, withTime: boolean) {
        return withTime
            ? `${a.getFullYear()}-${a.getMonth() + 1}-${a.getDate()} ${a.getHours()}:${a.getMinutes()}`
            : `${a.getFullYear()}-${a.getMonth() + 1}-${a.getDate()}`
    }

    private get calendarInstance(): Vue & { prev: () => void; next: () => void; checkChange: () => void; getFormatter: (format: any) => any } {
        return this.$refs.calendar as Vue & { prev: () => void; next: () => void; checkChange: () => void; getFormatter: (format: any) => any }
    }
}
export type Start = {
    date: string;
    time: string;
    year: number;
    month: number;
    day: number;
    hour: number;
    minute: number;
    weekday: number;
    hasDay: boolean;
    hasTime: boolean;
    past: boolean;
    present: boolean;
    future: boolean;
}
export type End = {
    date: string;
    time: string;
    year: number;
    month: number;
    day: number;
    hour: number;
    minute: number;
    weekday: number;
    hasDay: boolean;
    hasTime: boolean;
    past: boolean;
    present: boolean;
    future: boolean;
}
export type Event = {
    name: string;
    start: string;
    end: string;
    color: string;
    details:string;
}
